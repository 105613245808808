import React, { useContext, useEffect, useRef, useState } from 'react';
import PageLayout from 'layout/page/Page.layout';
import { Container } from 'layout/page/Page.layout.styled';
import { QuestionnaireMainWrapper } from 'pages/questionnaire/Questionnaire.styled';
import { SwiperSlide } from 'swiper/react';
import Slider from 'components/slider/Slider';
import { Swiper as SwiperClass } from 'swiper/types';
import QuestionnaireHome from 'pages/questionnaire/slides/home/QuestionnaireHome';
import QuestionnaireProfileForm from 'pages/questionnaire/slides/profile/QuestionnaireProfileForm';
import useAPIQuestionnaire, { IQuestionnaireData } from 'hooks/api/useAPIQuestionnaire';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import QuestionnaireMain from 'pages/questionnaire/slides/questionnaire/QuestionnaireMain';
import QuestionnaireEnd from 'pages/questionnaire/slides/end/QuestionnaireEnd';
import useUpdateChecker from 'hooks/useUpdateChecker';
import useAPIAuth from 'hooks/api/useAPIAuth';
import { Questionnaire as QuestionnaireType } from 'types/app';
import { PRIMARY_QUESTIONNAIRE_NB, TOTAL_QUESTIONNAIRE } from 'constants/global';
import { ROUTES } from 'constants/routes';
import { setAnsweredQuestionnaireCookie } from 'helpers/utils';

const Questionnaire: React.FC = () => {
  const { checkUserDetails } = useAPIAuth();
  useUpdateChecker();
  const { user } = useContext(UserContext) as UserContextProps;
  const { loadQuestionnaireData, loadAdditionalQuestionnaire } = useAPIQuestionnaire();
  const swiperInstance = useRef<SwiperClass>();
  const [slidesContents, updateSlidesContents] = useState<Array<React.ReactElement>>([]);
  const [topicNumbers, setTopicNumbers] = useState<number>(0);
  const sectionIds = useRef<number[]>([]);
  const currentChapterSectionNumber = useRef<number>(0);

  /**
   * This function, `insertTransitionsAtBeginning`, processes an array of questions and inserts transition objects at the beginning of each new section.
   * 
   * It takes an array of questions (`originalQuestionsArray`) as input and returns a new array with transition objects added.
   * 
   * The function initializes several variables:
   * - `notPassedChapterSectionIds`: an array to track section IDs that have not been fully answered.
   * - `tmpCurrentChapterSectionNumber`: a temporary counter for the current chapter section number.
   * - `resultArray`: an array to store the processed questions and transition objects.
   * - `tmpTopicNumbers`: a temporary counter for the number of topics, initialized with the current `topicNumbers`.
   * - `nextSectionLabel`: an object to hold the label of the next section.
   * 
   * The function iterates over each question in the input array. For each question, it checks if the current question is the last in its section.
   * If so, it updates `nextSectionLabel` with the label of the next section.
   * 
   * A transition object is created with default values and the `nextSectionLabel`. This object is inserted at the beginning of each new section.
   * 
   * The function also tracks sections that have not been fully answered using `notPassedChapterSectionIds`. If a question has a response and its section ID is in this list, the section ID is removed from the list, and the chapter section number is incremented.
   * 
   * Finally, the function updates the `currentChapterSectionNumber` and `topicNumbers` with the temporary values and returns the `resultArray`.
   */
  function insertTransitionsAtBeginning(originalQuestionsArray: QuestionnaireType.IQuestion[]) {
    let notPassedChapterSectionIds: number[] = [];
    const tmpSectionIds: number[] = [];
    let tmpCurrentChapterSectionNumber = 0;
    const resultArray: any[] = [];
    let tmpTopicNumbers = topicNumbers;

    let nextSectionLabel = {};

    originalQuestionsArray.forEach((currentItem, index, array) => {
      if (index < array.length - 1 && currentItem.sectionId !== array[index + 1].sectionId) {
        nextSectionLabel = array[index + 1].sectionLabel;
      }

      if (index === 0 && originalQuestionsArray.length > 0) {
        nextSectionLabel = array[index + 1].sectionLabel;
      }

      const transitionObject: QuestionnaireType.IQuestion = {
        id: -1,
        sectionId: -1,
        sectionLabel: nextSectionLabel,
        label: {},
        order: -1,
        responseMax: -1,
        responseMin: -1,
        response: -1,
      };

      if (index === 0 || currentItem.sectionId !== array[index - 1].sectionId) {
        resultArray.push(transitionObject);
        tmpSectionIds.push(currentItem.sectionId);
        notPassedChapterSectionIds.push(currentItem.sectionId);
        tmpTopicNumbers += 1;
      }

      resultArray.push(currentItem);
      if (currentItem.response && notPassedChapterSectionIds.includes(currentItem.sectionId)) {
        notPassedChapterSectionIds = notPassedChapterSectionIds.filter(
          (id) => id !== currentItem.sectionId,
        );
        tmpCurrentChapterSectionNumber += 1;
      }
    });
    sectionIds.current = tmpSectionIds;
    currentChapterSectionNumber.current = tmpCurrentChapterSectionNumber;
    setTopicNumbers(tmpTopicNumbers);
    return resultArray;
  }

  const findUnansweredQuestionsBeforeCurrentQuestion = (questions: QuestionnaireType.IQuestion[]): {
    unansweredQuestions: QuestionnaireType.IQuestion[];
    currentQuestionIndex: number;
  } => {
    const lastResponseIndex = questions.reduce((lastIndex, question, index) => {
      return question.response !== undefined ? index : lastIndex;
    }, -1);
    const lastResponse = questions[lastResponseIndex];
    if (!lastResponse) {
      return {
        unansweredQuestions: [],
        currentQuestionIndex: 0
      };
    };
    const unansweredQuestions = questions.filter(question => question.order < lastResponse.order && !question.response);
    return {
      unansweredQuestions,
      currentQuestionIndex: lastResponseIndex + 1,
    };
  };

  const createSlides = async (qData: IQuestionnaireData) => {
    const slides: Array<React.ReactElement> = [];
    qData.textIntro && slides.push(<QuestionnaireHome textIntro={qData.textIntro} />);
    if (!user?.profileInfo?.activity || !user?.civilityInDatabase) {
      slides.push(
        <QuestionnaireProfileForm
          activities={qData.activities ?? []}
          currentActivity={user?.profileInfo?.activity ?? undefined}
          currentBirthYear={user?.profileInfo?.birthYear}
          currentCivility={user?.civilityInDatabase}
        />,
      );
    }

    if (!qData.questionnaire || qData.questionnaire.questions.length === 0) {
      return;
    }

    const resp = findUnansweredQuestionsBeforeCurrentQuestion(qData.questionnaire?.questions ?? []);
    if (resp?.unansweredQuestions && resp.unansweredQuestions.length > 0) {
      const { unansweredQuestions, currentQuestionIndex } = resp;
      const startArray = (qData.questionnaire.questions.slice(0, currentQuestionIndex).filter(question => {
        return !unansweredQuestions.some(unanswered => unanswered.id === question.id);
      }));
      const endArray = insertTransitionsAtBeginning(qData.questionnaire.questions.slice(currentQuestionIndex).filter(question => {
        return !unansweredQuestions.some(unanswered => unanswered.id === question.id);
      }));

      let removeFirstTransitionForEndArray = false;

      if (unansweredQuestions?.length > 0 && endArray?.length > 0) {
        if (unansweredQuestions[0].sectionId === endArray[1].sectionId) {
          removeFirstTransitionForEndArray = true;
        }
  
        if (removeFirstTransitionForEndArray) {
          endArray.shift();
        }
      }

      const modifiedArray = [...insertTransitionsAtBeginning(startArray), ...unansweredQuestions, ...(endArray)];
      const modifiedArrayAllQuestions = insertTransitionsAtBeginning(qData.questionnaire.questions);
      slides.push(
        <QuestionnaireMain
          q={{
            ...qData.questionnaire,
            questions: [...modifiedArray],
            currentQuestionIndex: [...modifiedArray].findIndex(question => question.id === unansweredQuestions[0].id),
            progression: qData.questionnaire.progression,
            allQuestions: [...modifiedArrayAllQuestions]
          }}
          topicNumbers={0}
        />,
      );
      slides.push(<QuestionnaireEnd />);
      updateSlidesContents(slides);
      return;
    }

    if (qData.questionnaire && qData.questionnaire?.progression < Math.floor(PRIMARY_QUESTIONNAIRE_NB * 100 / TOTAL_QUESTIONNAIRE)) {
      const modifiedArray = insertTransitionsAtBeginning(qData.questionnaire.questions);
      slides.push(
        <QuestionnaireMain
          q={{
            ...qData.questionnaire,
            questions: [...modifiedArray],
            currentQuestionIndex:
              qData.questionnaire.currentQuestionIndex + currentChapterSectionNumber.current,
          }}
          topicNumbers={0}
        />,
      );
      slides.push(<QuestionnaireEnd />);
      updateSlidesContents(slides);
      return;
    } else {
      const additionalQData = await loadAdditionalQuestionnaire();
      if (!additionalQData.questionnaire) {
        return;
      }
      const resp = findUnansweredQuestionsBeforeCurrentQuestion(additionalQData.questionnaire?.questions ?? []);

      if (resp?.unansweredQuestions && resp.unansweredQuestions.length > 0) {
        const { unansweredQuestions, currentQuestionIndex } = resp;
        const startArray = additionalQData.questionnaire.questions.slice(0, currentQuestionIndex).filter(question => {
          return !unansweredQuestions.some(unanswered => unanswered.id === question.id);
        });
        const endArray = additionalQData.questionnaire.questions.slice(currentQuestionIndex);
        const modifiedArray = [...startArray, ...unansweredQuestions, ...endArray];
        slides.push(
          <QuestionnaireMain
            q={{
              ...additionalQData.questionnaire,
              questions: [...modifiedArray],
              currentQuestionIndex: [...modifiedArray].findIndex(question => question.id === unansweredQuestions[0].id),
            }}
            topicNumbers={0}
            isAdditional={true}
          />,
        );
        slides.push(<QuestionnaireEnd />);
        updateSlidesContents(slides);
        return;
      }

      if (additionalQData && additionalQData.questionnaire && additionalQData.questionnaire.progression < 100) {
        slides.push(
          <QuestionnaireMain
            q={{
              ...additionalQData.questionnaire,
              id: 21,
              questions: [...additionalQData.questionnaire.questions],
              currentQuestionIndex:
                additionalQData.questionnaire.currentQuestionIndex + currentChapterSectionNumber.current,
            }}
            topicNumbers={0}
            isAdditional={true}
          />,
        );
      } else {
        if (additionalQData.questionnaire && additionalQData.questionnaire.progression >= 100) {
          setAnsweredQuestionnaireCookie();
          window.location.href = ROUTES.INDEX;
        }
      }
    }
    slides.push(<QuestionnaireEnd />);
    updateSlidesContents(slides);
    return;
  };

  const loadData = async () => {
    user && await checkUserDetails(user.id, user.token);
    await createSlides(await loadQuestionnaireData());
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <PageLayout
      headerProps={{ showLogo: true, hideNotification: true, showLangSwitcher: true }}
      className="page-layout-questionnaire"
    >
      <QuestionnaireMainWrapper>
        <Container padding={0} className="app-page">
          <Slider
            onInit={(swiper: SwiperClass) => {
              swiperInstance.current = swiper;
            }}
            allowTouchMove={false}
          >
            {slidesContents.map((Compo, index) => (
              <SwiperSlide key={`compo-${index}`}>{Compo}</SwiperSlide>
            ))}
          </Slider>
        </Container>
      </QuestionnaireMainWrapper>
    </PageLayout>
  );
};

export default Questionnaire;
