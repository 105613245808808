import React, { useContext, useMemo } from 'react';
import {
  HeaderPageWrapper,
  HeaderRightSideWrapper,
  PageHeaderLogoWrapper,
  PageTitleWrapper,
} from 'components/header/Header.main.styled';
import IconNotification from 'components/common/icons/icon-notification/IconNotification';
import { NotificationsContext, NotificationsContextProps } from 'contexts/NotificationsContext';
import { useLocation } from 'react-router';
import { matchPath } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import { useIonRouter } from '@ionic/react';
import { ReactComponent as LogoComcolors } from 'assets/svg/logo-comcolors.svg';
import LanguageSelector from 'components/header/languageselector/Language.selector';
import { UserContext, UserContextProps } from 'contexts/UserContext';

export interface HeaderPageProps {
  title?: string | null;
  hideNotification?: boolean;
  showLangSwitcher?: boolean;
  showLogo?: boolean;
}

const HeaderPage: React.FC<HeaderPageProps> = ({
  title,
  hideNotification,
  showLangSwitcher,
  showLogo,
}): React.ReactElement => {
  const { hasNewNotifications } = useContext(NotificationsContext) as NotificationsContextProps;
  const { user } = useContext(UserContext) as UserContextProps;
  const ionRouter = useIonRouter();
  const location = useLocation();
  const pathname = location.pathname || location.location.pathname;
  const matchNotificationPage = matchPath(pathname, {
    path: ROUTES.NOTIFICATIONS,
    exact: true,
  });
  const onClickNotif = () => {
    ionRouter.push(ROUTES.NOTIFICATIONS);
  };

  const LeftSide = useMemo(() => {
    const Component: React.FC = () => {
      if (title) {
        return <PageTitleWrapper>{title}</PageTitleWrapper>;
      } else {
        if (showLogo) {
          return (
            <PageHeaderLogoWrapper>
              <LogoComcolors />
            </PageHeaderLogoWrapper>
          );
        }
      }

      return <div />;
    };
    return Component;
  }, [title, showLogo]);

  const RightSide = useMemo(() => {
    const Component: React.FC = () => {
      if (!hideNotification) {
        return (
          <>
            {user && (
              <IconNotification
                hasNotification={hasNewNotifications}
                isNotificationPage={!!matchNotificationPage}
                onClick={onClickNotif}
              />
            )}
          </>
        );
      } else {
        if (showLangSwitcher) {
          return <LanguageSelector />;
        }
      }

      return <div />;
    };

    const wrappedComponent: React.FC = () => {
      return (
        <HeaderRightSideWrapper>
          <Component />
        </HeaderRightSideWrapper>
      );
    };
    return wrappedComponent;
  }, [
    hideNotification,
    showLangSwitcher,
    matchNotificationPage,
    hasNewNotifications,
  ]);

  return (
    <HeaderPageWrapper>
      <LeftSide />
      <RightSide />
    </HeaderPageWrapper>
  );
};

export default HeaderPage;
