import { SwiperSlide } from 'swiper/react';
import React, { useState } from 'react';
import Slider from 'components/slider/Slider';
import VideoThumbnail from 'components/slider/video-thumbnails-slider/VideoThumbnail';
import { VideoThumbnailsSliderWrapper } from 'components/slider/video-thumbnails-slider/VideoThumbnailsSlider.styled';
import { IVideos } from 'types/app';
import { getLocaleValue } from 'helpers/utils';
import { useTranslation } from 'react-i18next';

export interface VideoThumbnailsSliderProps {
  thumbnails: IVideos;
  onSelectItem?: (videoUrl: string) => void;
  showBullets?: boolean;
  thumbnailLoader?: React.ReactNode;
  cssProps?: string;
}

const VideoThumbnailsSlider: React.FC<VideoThumbnailsSliderProps> = ({
  thumbnails,
  onSelectItem,
  showBullets,
  thumbnailLoader,
  cssProps = ''
}) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);

  const { i18n } = useTranslation('translation');

  return (
    <VideoThumbnailsSliderWrapper cssProps={cssProps}>
      <Slider
        slidesPerView={'auto'}
        spaceBetween={25}
        slidesOffsetAfter={25}
        slidesOffsetBefore={25}
        onSlideChange={(e) => {
          setCurrentSlideIndex(e.activeIndex || 0);
        }}
      >
        {thumbnails.map((t, index) => {
          return (
            <SwiperSlide key={`video-${index}`}>
              <VideoThumbnail
                imageUrl={t.imageUrl}
                thumbnailLoader={thumbnailLoader}
                onClick={() => {
                  onSelectItem && onSelectItem(getLocaleValue(t.videoUrl, i18n.language));
                }}
                title={getLocaleValue(t.title, i18n.language)}
              />
            </SwiperSlide>
          );
        })}
        {showBullets && (
          <div className="slider-bullets">
            {thumbnails.map((t, index) => {
              return (
                <div
                  key={`${t.title}-${index}`}
                  className={`bullet ${currentSlideIndex === index ? 'active' : ''}`}
                />
              );
            })}
          </div>
        )}
      </Slider>
    </VideoThumbnailsSliderWrapper>
  );
};

export default VideoThumbnailsSlider;
