import React, { useContext, useEffect, useRef, useState } from 'react';
import { IonRouterOutlet, IonTabBar, IonTabButton } from '@ionic/react';
import { Components } from '@ionic/core';
import IconTab from 'components/common/icons/icon-tab/IconTab';
import { IonTabButtonLabel, IonTabsStyled } from 'layout/ionic/IonTabBar.styled';
import {
  MobileLayoutWrapper,
  MoreMenu,
  MoreMenuWrapper,
} from 'layout/main/mobile/Mobile.layout.styled';
import ButtonClose from 'components/common/button/ButtonClose';
import { ICON_NAMES } from 'hooks/useIcons';
import AppMenu from 'components/menu/AppMenu';
import { LayoutProps } from 'layout/main/Main.layout';
import { Redirect, Route, useLocation } from 'react-router';
import { ROUTES } from 'constants/routes';
import Home from 'pages/home/Home';
import IconProfile from 'components/common/icons/icon-profile/IconProfile';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import Settings from 'pages/settings/Settings';
import Profile from 'pages/profile/Profile';
import Resources from 'pages/resources/Resources';
import RelationsHome from 'pages/relations/home/RelationsHome';
import AddRelation from 'pages/relations/add-relation/AddRelation';
import RelationIdentification from 'pages/relations/identification/RelationIdentification';
import RelationManagement from 'pages/relations/manage/RelationManagement';
import Faqs from 'pages/faqs/Faqs';
import Training from 'pages/training/Training';
import Notifications from 'pages/notifications/Notifications';
import useAPIAdvices, { AdviceType } from 'hooks/api/useAPIAdvices';
import useUpdateChecker from 'hooks/useUpdateChecker';
import useCheckInstallation from 'hooks/useCheckInstallation';
import Models from 'pages/models/Models';
import RelationAppointment from 'pages/relations/appointment/RelationAppointment';
import Contact from 'pages/contact/Contact';
import FindTrainer from 'pages/find-trainer/FindTrainer';

const MobileLayout: React.FC<LayoutProps> = ({
  mainEntries,
  moreMenu = [],
}): React.ReactElement => {
  useCheckInstallation();
  useUpdateChecker();
  const location = useLocation();
  const pathname = location.pathname || location.location.pathname;
  const { user, isGuest } = useContext(UserContext) as UserContextProps;
  const { loadNotifications } = useAPIAdvices();
  const oldPath = useRef<string>('');
  const notificationsTimeoutId = useRef<number>(0);
  const mobileLayoutEl = useRef<HTMLDivElement | null>(null);
  const moreMenuContainerEl = useRef<HTMLDivElement | null>(null);
  const [moreMenuState, setMoreMenuState] = useState(false);
  const [tabInnerHeight, setTabInnerHeight] = useState<number>(0);

  const hideMoreMenu = () => {
    setMoreMenuState(false);
  };
  const onClickMore = (e: Event | null) => {
    e && e.preventDefault();
    setMoreMenuState(!moreMenuState);
  };
  const notificationLoadDelayer = (action: () => void) => {
    clearTimeout(notificationsTimeoutId.current);
    notificationsTimeoutId.current = window.setTimeout(() => {
      if (!pathname.includes('notifications')) {
        action();
      }
    }, 250);
  };
  const handlePopstateChange = (event: PopStateEvent) => {
    event.preventDefault();
  };
  const handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      notificationLoadDelayer(() => {
        user && loadNotifications(AdviceType.ALL, user.id, true);
      });
    }
  };
  const handleWindowResize = () => {
    setTimeout(() => {
      const innerContainer = mobileLayoutEl.current?.querySelector('.tabs-inner');

      if (innerContainer) {
        setTabInnerHeight(innerContainer.getBoundingClientRect().height);
      }
    }, 250);
  };
  const loadNotificationsInBackground = async () => {
    let path = pathname;

    pathname.includes('profile') && (path = 'profile');
    pathname.includes('relation') && (path = 'relation');

    notificationLoadDelayer(() => {
      if (oldPath.current !== path && user) {
        loadNotifications(AdviceType.ALL, user.id, true);
        oldPath.current = path;
      }
    });
  };

  useEffect(() => {
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);
    window.addEventListener('popstate', handlePopstateChange);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('popstate', handlePopstateChange);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    loadNotificationsInBackground();
  }, [pathname]);

  const resolveUserOnlyAccessibleRoute = (route: any, fallbackUrl: string = ROUTES.HOME): any => {
    if (user) {
      return route;
    } else {
      window.location.href = fallbackUrl;
    }
  };

  return (
    <MobileLayoutWrapper ref={mobileLayoutEl}>
      <MoreMenuWrapper height={tabInnerHeight}>
        <MoreMenu
          ref={moreMenuContainerEl}
          className={moreMenuState ? 'open app-page-max-w' : 'close app-page-max-w'}
        >
          <ButtonClose
            onClick={() => {
              onClickMore(null);
            }}
          />
          <AppMenu entries={[...moreMenu]} onSelectItem={hideMoreMenu} />
        </MoreMenu>
      </MoreMenuWrapper>
      <IonTabsStyled>
        <IonRouterOutlet animated={false}>
          <Route exact path={ROUTES.HOME} render={() => <Home />} />
          <Route exact path={ROUTES.SETTINGS} render={() => <Settings />} />
          <Route
            exact
            path={`${ROUTES.MODEL.INDEX}/:section?/:section2?`}
            render={() => <Models />}
          />
          <Route exact path={ROUTES.RELATIONS.INDEX} render={() => <RelationsHome />} />
          <Route
            exact
            path={ROUTES.RELATIONS.ADD}
            render={() => resolveUserOnlyAccessibleRoute(<AddRelation />)}
          />
          <Route
            exact
            path={`${ROUTES.RELATIONS.IDENTIFY}/:idRelation`}
            render={() => resolveUserOnlyAccessibleRoute(<RelationIdentification />)}
          />
          <Route
            exact
            path={`${ROUTES.RELATIONS.MANAGE}/:idRelation`}
            render={() => resolveUserOnlyAccessibleRoute(<RelationManagement />)}
          />
          <Route
            exact
            path={`${ROUTES.RELATIONS.APPOINTMENT}/:idRelation`}
            render={() => resolveUserOnlyAccessibleRoute(<RelationAppointment />)}
          />
          <Route exact path={`${ROUTES.PROFILE.INDEX}/:section?`} render={() => <Profile />} />
          <Route exact path={`${ROUTES.RESOURCES.INDEX}/:section?`} render={() => <Resources />} />
          <Route exact path={ROUTES.FAQ} render={() => <Faqs />} />
          <Route exact path={ROUTES.TRAINING} render={() => <Training />} />
          <Route exact path={ROUTES.CONTACT} render={() => <Contact />} />
          <Route exact path={ROUTES.FIND_TRAINER} render={() => <FindTrainer />} />
          <Route
            exact
            path={ROUTES.NOTIFICATIONS}
            render={() => resolveUserOnlyAccessibleRoute(<Notifications />)}
          />
          <Redirect to={ROUTES.HOME} />
        </IonRouterOutlet>
        <IonTabBar slot="bottom" id="main-tab-bar">
          {!!mainEntries?.length &&
            mainEntries.map((item, index) => {
              const buttonProps: Partial<Components.IonTabButton> = {
                tab: item.name,
              };
              // If have route
              if (item.route) {
                buttonProps.href = item.route;
              }

              if (item.hrefs) {
                buttonProps.selected = item.hrefs.some((href) => pathname.indexOf(href) !== -1);
              }

              return (
                <IonTabButton
                  {...buttonProps}
                  key={`${item.name}-${index}`}
                  onClick={item.name === ICON_NAMES.MORE ? onClickMore : hideMoreMenu}
                >
                  <IconTab>
                    {item.name === ICON_NAMES.PROFILE && user?.profileInfo?.hasVisibleProfile ? (
                      <IconProfile
                        primaryColorId={
                          !isGuest ? user?.profileInfo?.colors?.primary?.id : undefined
                        }
                        secondaryColorId={
                          !isGuest ? user?.profileInfo?.colors?.secondary?.id : undefined
                        }
                      />
                    ) : (
                      item.icon
                    )}
                  </IconTab>
                  <IonTabButtonLabel>{item.label}</IonTabButtonLabel>
                </IonTabButton>
              );
            })}
        </IonTabBar>
      </IonTabsStyled>
    </MobileLayoutWrapper>
  );
};

export default MobileLayout;
