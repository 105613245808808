import React from 'react';
import { IProduct } from 'types/app';
import {
  BookItemDesc,
  BookItemImage,
  BookItemTitle,
  BookItemWrapper,
} from 'components/resources/book/BookItem.styled';
import { getLocaleValue } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/button/Button';
import { GenericDiv } from 'styles/helpers/utils';
import { prem } from 'styles/helpers/spacing';

const BookItem: React.FC<IProduct> = ({ title, description, imageUrl, getUrl }) => {
  const { t, i18n } = useTranslation('translation');

  return (
    <BookItemWrapper>
      {title && <BookItemTitle>{getLocaleValue(title, i18n.language)}</BookItemTitle>}
      {imageUrl && <BookItemImage src={imageUrl} />}
      {getUrl && (
        <GenericDiv minWidth={prem(100)} maxWidth={prem(200)} center>
          <Button color="accent" href={getUrl} target="_blank" expand>
            {t('common.get-book')}
          </Button>
        </GenericDiv>
      )}
      <BookItemDesc
        dangerouslySetInnerHTML={{ __html: getLocaleValue(description, i18n.language) }}
      />
    </BookItemWrapper>
  );
};

export default BookItem;
