import styled, {css} from 'styled-components';
import { centerize, transparentEl } from 'styles/helpers/utils';
import { prem } from 'styles/helpers/spacing';
import { FONT_SIZES } from 'styles/constants/fonts';

const PictoPlayContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${prem(40)};
  height: ${prem(40)};
  color: var(--ion-color-highlight);

  svg {
    width: 100%;
    height: auto;
  }
`;
const VideoThumbnailsSliderWrapper = styled.div<{ cssProps?: string }>`
  .slider-bullets {
    margin: 0 auto;
    width: max-content;
    display: flex;
    padding-top: ${prem(10)};

    .bullet {
      width: ${prem(9)};
      height: ${prem(9)};
      border-radius: ${prem(50)};
      border: ${prem(1)} solid var(--ion-text-color);
      margin-left: ${prem(7)};
      background-color: transparent;

      &.active {
        border: ${prem(1)} solid var(--ion-color-accent);
        background-color: var(--ion-color-accent);
      }
    }
  }
  ${({ cssProps }) => cssProps || ''};
`;

const VideoThumbnailWrapper = styled.button<{ height?: string, minHeight?: string }>`
  display: block;
  position: relative;
  ${transparentEl};
  ${centerize};
  flex-direction: column;
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `};
  ${({ minHeight }) =>
    minHeight &&
    css`
      min-height: ${minHeight};
    `};
`;

const VideoTitle = styled.p`
  color: var(--ion-text-color);
  font-size: ${prem(FONT_SIZES.BODY.DESKTOP_S)};
  padding-top: ${prem(8)};
`;

export { PictoPlayContainer, VideoTitle, VideoThumbnailWrapper, VideoThumbnailsSliderWrapper };
