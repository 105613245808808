import React, { useContext } from 'react';
import { IModule } from 'types/app';
import { SingleModuleWrapper } from 'components/resources/module/single-module/SingleModule.styled';
import { removeAttributes } from 'helpers/utils';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { GenericDiv } from 'styles/helpers/utils';
import { MODULES_CODES } from 'constants/global';
import AccessModule from 'components/resources/module/single-module/access-module/AccessModule';
import useIonNavContext from 'hooks/useIonNavContext';
import {
  disableGetModuleButton,
  hideGetModuleButton,
  showBElearningButton,
} from 'helpers/modules';
import Button from 'components/common/button/Button';
import { VideoPlayerContext, VideoPlayerContextProps } from 'contexts/VideoPlayerContext';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import GetModuleButton from 'components/resources/module/single-module/get-module/buy-module-button/GetModuleButton';
import { ROUTES } from 'constants/routes';

export interface SingleModuleProps {
  module: IModule;
}
const SingleModule: React.FC<SingleModuleProps> = ({ module }) => {
  const { user } = useContext(UserContext) as UserContextProps;
  const { setVideoUrl } = useContext(VideoPlayerContext) as VideoPlayerContextProps;
  const onPlayVideo = (videoUrl: string) => {
    setVideoUrl(videoUrl);
  };
  const { gotoUrl } = useIonNavContext();
  const {
    icons: [video],
  } = useIcons([ICON_NAMES.VIDEO]);

  return (
    <SingleModuleWrapper>
      <div
        className="single-module-description"
        dangerouslySetInnerHTML={{ __html: removeAttributes(module.description) }}
      />
      {user ? (
        <>
          {!module.hasAccess && (
            <GenericDiv minWidth={'7rem'} maxWidth={'21rem'} center>
              <>
                {module.code !== MODULES_CODES.ELEARNING && (
                  <>
                    {!hideGetModuleButton(user) && (
                      <GetModuleButton
                        module={module}
                        hasAccessToModule={module.hasAccess}
                        disable={disableGetModuleButton(user)}
                        onClick={() => {
                          gotoUrl(ROUTES.CONTACT);
                        }}
                      />
                    )}
                  </>
                )}
                {module.code === MODULES_CODES.ELEARNING && (
                  <>
                    {showBElearningButton(user) && (
                      <GetModuleButton
                        module={module}
                        hasAccessToModule={module.hasAccess}
                        onClick={() => {
                          gotoUrl(ROUTES.CONTACT);
                        }}
                      />
                    )}
                  </>
                )}
              </>
            </GenericDiv>
          )}
          {module.hasAccess && (
            <>
              <GenericDiv minWidth={'7rem'} maxWidth={'21rem'} center>
                <AccessModule module={module} />
              </GenericDiv>
            </>
          )}
          {module.videoCta && module.videoUrl && (
            <>
              <GenericDiv minWidth={'7rem'} maxWidth={'21rem'} center>
                <Button
                  onClick={() => onPlayVideo(module.videoUrl as string)}
                  fill="solid"
                  color="accent"
                >
                  <div className="video">{video.icon}</div> {module.videoCta}
                </Button>
              </GenericDiv>
            </>
          )}
        </>
      ) : (
        <>{/** No user */}</>
      )}
    </SingleModuleWrapper>
  );
};

export default SingleModule;