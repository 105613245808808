import useFetch from 'hooks/api/useFetch';
import { API, LANGUAGES } from 'constants/global';
import { IUser } from 'types/app';
import { useContext } from 'react';
import useAPISettings from 'hooks/api/useAPISettings';
import { SettingsContext, SettingsContextProps } from 'contexts/SettingsContext';
import { AxiosRequestConfig } from 'axios';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';
import { IHttpResponse } from 'helpers/api';
import { parseUser, parseUserInfo } from 'helpers/parsers';
import { useRollbar } from '@rollbar/react';
import useIonNavContext from 'hooks/useIonNavContext';
import { ROUTES } from 'constants/routes';
import { removeFromStorage } from 'helpers/storage';
import { removeAnsweredQuestionnaireCookie, replaceStrings } from 'helpers/utils';

export const USER_PROFILE_INFO_SOURCE = {
  ACADEMY: 'academy',
  QUESTIONNAIRE: 'questionnaire',
  MANAGEMENT: 'gestion',
};

export const USER_PROFILE_INFO_GROUP_TYPE = {
  ENTERPRIS: 'entreprise',
  INDIVIDUAL: 'particulier',
};

export interface IUseAPIAuth {
  register: (
    name: string,
    firstname: string,
    pronoun: string,
    email: string,
    password: string,
    acceptCgu: number,
  ) => Promise<undefined | number>;
  doLogin: (email?: string, password?: string, token?: string) => Promise<boolean | undefined>;
  sendForgottenPwdEmail: (email: string) => Promise<boolean | undefined>;
  sendForgottenPwdCode: (code: string) => Promise<boolean | undefined>;
  sendForgottenPwdNewPwd: (
    password: string,
    email: string,
    code: string,
  ) => Promise<boolean | undefined>;
  logout: () => Promise<void>;
  checkUserDetails: (
    userId: number,
    token?: { value: string; type: string } | null,
  ) => Promise<IUser | undefined>;
  getUseDetail: (
    user: IUser,
    isInBackground?: boolean,
    token?: { value: string; type: string } | null,
  ) => Promise<IUser | undefined>;
  getUserAutoLoginToken: (
    userId: number,
    token?:
      | {
          value: string;
          type: string;
        }
      | undefined,
  ) => Promise<string | undefined>;
}

function useAPIAuth(): IUseAPIAuth {
  const rollbar = useRollbar();
  const { setToast } = useContext(ToastContext) as ToastContextProps;
  const { updateUser } = useContext(UserContext) as UserContextProps;
  const { updateSettings, changeLanguage } = useContext(SettingsContext) as SettingsContextProps;
  const { getSettings } = useAPISettings();
  const { getRequest, showLoading, hideLoading } = useFetch<any>();
  const { gotoUrl } = useIonNavContext();

  // Handle register request
  const register = async (
    name: string,
    firstname: string,
    pronoun: string,
    email: string,
    password: string,
    acceptCgu: number,
  ) => {
    showLoading();

    try {
      const dataRegister = await getRequest({
        url: API.ROUTES.AUTH.REGISTER,
        method: 'post',
        data: {
          nom: name,
          prenom: firstname,
          pronom: pronoun,
          email: email,
          password,
          accepte_cgu: acceptCgu,
        },
        headers: {
          Authorization: API.BASIC_TOKEN,
        },
      });

      if (dataRegister?.data?.data?.user) {
        const isLoggedIn = await doLogin(email, password);

        if (isLoggedIn) {
          window.location.href = ROUTES.INDEX;
        }
      } else {
        setToast({
          message: 'Error while registering',
          status: 'danger',
        });
      }
    } catch (e: any) {
      if (e?.data && e.data.status_code) {
        return e.data.status_code as number;
      } else {
        let msg = '';

        if (e?.hasError) {
          if (e?.statusText) {
            msg = e?.statusText;
          } else if (e?.message) {
            msg = e?.message;
          }
        }
        rollbar.error(msg);
        setToast({
          message: msg,
          status: 'danger',
        });
      }
    } finally {
      hideLoading();
    }

    return undefined;
  };

  // Handle login request
  const doLogin = async (email?: string, password?: string, token?: string) => {
    const formData = new FormData();
    formData.append('email', email || '');
    formData.append('password', password || '');
    showLoading();

    try {
      /*
      if (!token) {
        rollbar.info('-- Trying to use autologin with an invalid token --', {
          token: token,
        });
        return false;
      }
      */
      const dataLogin = await getRequest({
        url:
          email && password
            ? API.ROUTES.AUTH.LOGIN
            : `${API.ROUTES.AUTH.AUTO_LOGIN}?token=${token}`,
        method: 'post',
        data: email && password ? formData : null,
        headers: {
          Authorization: API.BASIC_TOKEN,
        },
      });

      let userData: IUser | undefined = parseUser(dataLogin.data?.data, '');

      if (userData?.id) {
        const userSettings = await getSettings(
          userData.id,
          `${userData.token?.type} ${userData.token?.value}`,
        );
        const userWithProfile = await getUseDetail(userData, true, userData.token);
        userWithProfile && (userData = { ...userWithProfile });

        // Force user language to the selected language
        // const lsLang = getFromStorage(LS_KEYS.LANG);
        userSettings &&
          updateSettings({
            ...userSettings,
            // ...(lsLang && { lang: lsLang.includes('fr') ? 'fr' : 'en' }),
          });

        updateUser(userData);

        rollbar.info('-- User logged in --', {
          id: `${userData?.id}`,
          name: `${userData.firstname}`,
          email: `${userData.email}`,
        });
        return true;
      }
    } catch (e) {
      const msg: string = (e as Error).message || '';
      rollbar.info('-- Autogolin error --', {
        message: msg,
        token: token,
      });
      setToast({
        message: msg,
        status: 'danger',
      });
      return false;
    } finally {
      hideLoading();
    }
    return false;
  };

  // Forgotten pwd: Send email
  const sendForgottenPwdEmail = async (email: string): Promise<boolean | undefined> => {
    const formData = new FormData();

    formData.append('email', email);
    showLoading();

    try {
      const result: IHttpResponse = await getRequest({
        url: API.ROUTES.AUTH.FORGOTTEN_PWD.FORGOTTEN_PWD,
        method: 'post',
        data: formData,
        headers: {
          Authorization: API.BASIC_TOKEN,
        },
      });

      if (result?.data?.data) {
        return true;
      }
    } catch (e) {
      console.log('log error: ', e);

      setToast({
        message: (e as Error).message || '',
        status: 'danger',
      });
      return undefined;
    } finally {
      hideLoading();
    }

    return false;
  };

  // Forgotten pwd: Send code
  const sendForgottenPwdCode = async (code: string): Promise<boolean | undefined> => {
    const formData = new FormData();

    formData.append('code', code);
    showLoading();

    try {
      const result: IHttpResponse = await getRequest({
        url: API.ROUTES.AUTH.FORGOTTEN_PWD.FORGOTTEN_PWD_CODE,
        method: 'post',
        data: formData,
        headers: {
          Authorization: API.BASIC_TOKEN,
        },
      });

      if (result?.data?.data) {
        return true;
      }
    } catch (e) {
      console.log('log error: ', e);

      setToast({
        message: (e as Error).message || '',
        status: 'danger',
      });
    } finally {
      hideLoading();
    }

    return false;
  };

  // Forgotten pwd: Send new password
  const sendForgottenPwdNewPwd = async (
    password: string,
    email: string,
    code: string,
  ): Promise<boolean | undefined> => {
    const formData = new FormData();

    formData.append('new_password', password);
    formData.append('email', email);
    formData.append('code', code);

    showLoading();

    try {
      const result: IHttpResponse = await getRequest({
        url: API.ROUTES.AUTH.FORGOTTEN_PWD.FORGOTTEN_PWD_NEW_PASSWORD,
        method: 'post',
        data: formData,
        headers: {
          Authorization: API.BASIC_TOKEN,
        },
      });

      if (result?.data?.data) {
        return true;
      }
    } catch (e) {
      console.log('log error: ', e);

      setToast({
        message: (e as Error).message || '',
        status: 'danger',
      });

      return undefined;
    } finally {
      hideLoading();
    }

    return false;
  };

  // Log out
  const logout = async () => {
    showLoading();
    try {
      await getRequest({
        url: API.ROUTES.AUTH.LOGOUT,
      });
    } catch (e) {
      console.log('Error while log out', e);
    }
    hideLoading();
    changeLanguage(LANGUAGES.fr);
    localStorage.removeItem('answered_questionnaire');
    removeAnsweredQuestionnaireCookie();
    return;
  };

  // check user details
  const checkUserDetails = async (
    userId: number,
    token?: { value: string; type: string } | null,
  ): Promise<IUser | undefined> => {
    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.USER.DETAIL}/${userId}`,
        ...(token ? { headers: { Authorization: `${token.type} ${token.value}` } } : {}),
      };
      const result: IHttpResponse = await getRequest(options);
      const userInfoState = result.data.data;
      if (!userInfoState.user_info.id) {
        removeFromStorage('user');
        gotoUrl(ROUTES.LOGIN);
      }
      return undefined;
    } catch (e: unknown) {
      setToast({
        message: 'Could not fetch user details',
        status: 'danger',
      });
    } finally {
      hideLoading();
    }
  };

  // Load user details
  const getUseDetail = async (
    user: IUser,
    isInBackground?: boolean,
    token?: { value: string; type: string } | null,
  ): Promise<IUser | undefined> => {
    !isInBackground && showLoading();

    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.USER.DETAIL}/${user.id}`,
        ...(token ? { headers: { Authorization: `${token.type} ${token.value}` } } : {}),
      };
      const result: IHttpResponse = await getRequest(options);
      const userInfoState = result.data.data;
      if (userInfoState && userInfoState.user_info) {
        const userWithProfile: IUser = parseUserInfo(user, userInfoState);
        !isInBackground && updateUser(userWithProfile);
        return userWithProfile;
      } else {
        if (!isInBackground) {
          if (result.data.message) {
            setToast({
              message: result.data.message,
              status: 'danger',
            });
          }
        }
      }

      return undefined;
    } catch (e: unknown) {
      console.log('Error while updating relation: ', e);
      if (!isInBackground) {
        setToast({
          message: (e as Error).message || '',
          status: 'danger',
        });
      }
    } finally {
      hideLoading();
    }

    return undefined;
  };

  const getUserAutoLoginToken = async (
    userId: number,
    token?:
      | {
          value: string;
          type: string;
        }
      | undefined,
  ) => {
    showLoading();
    try {
      const options: AxiosRequestConfig = {
        url: `${replaceStrings(API.ROUTES.AUTH.GET_AUTO_LOGIN_TOKEN, {
          userId: `${userId}`,
        })}`,
        method: 'get',
        headers: {
          Authorization: `${token?.type} ${token?.value}`,
        },
      };

      const result: IHttpResponse = await getRequest(options);
      const data = result.data.data;
      const userAutoLoginToken = data.user.access_token || '';
      return userAutoLoginToken;
    } catch (e) {
      console.log('Error while getting user auto login token: ', e);
    } finally {
      hideLoading();
    }
  };

  return {
    register,
    doLogin,
    sendForgottenPwdEmail,
    sendForgottenPwdCode,
    sendForgottenPwdNewPwd,
    logout,
    checkUserDetails,
    getUseDetail,
    getUserAutoLoginToken,
  };
}

export default useAPIAuth;
