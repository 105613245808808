import { IUser } from 'types/app';

export const hideGetModuleButton = (user: IUser) => {
  if (user) {
    if (!user.profileInfo?.colors?.primary) {
      return true;
    }
    if (!user.profileInfo?.isMemberTypeIndividualGroup) {
      if (user.profileInfo?.isDirectClientGroup) {
        if (!user.profileInfo.hasValidatedProfile) {
          return true;
        }
      } else {
        if (!user.profileInfo?.isAuthorizedToGetNewTools || !user.profileInfo.hasValidatedProfile) {
          return true;
        }
      }
    }
  }
  return false;
};

export const disableGetModuleButton = (user: IUser) => {
  if (user) {
    if (
      (user.profileInfo?.isMemberTypeIndividualGroup ||
        user.profileInfo?.isMemberTypeSiteMemberGroup) &&
      !user.profileInfo.hasValidatedProfile
    ) {
      return true;
    }
  }

  return false;
};

export const showBElearningButton = (user: IUser) => {
  let show = !hideGetModuleButton(user) && !disableGetModuleButton(user);
  if (user) {
    if (
      (user.profileInfo?.isMemberTypeIndividualGroup ||
        user.profileInfo?.isMemberTypeSiteMemberGroup) &&
      user.profileInfo.colors?.primary
    ) {
      show = true;
    }
  }

  return show;
};

// export const disableBuyElearninButton = (user: IUser) => {};
