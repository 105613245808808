import React, { useContext, useMemo } from 'react';
import {
  ColoursNames,
  RelationInteractionButtonSubscribe,
  RelationInteractionButtonSubscribeWrapper,
  RelationInteractionDesc,
  RelationInteractionWrapper,
} from 'components/relations/relation/interaction/RelationInteraction.styled';
import { IRelationInteraction, ITest, IUser } from 'types/app';
import { IconProfileProps } from 'components/common/icons/icon-profile/IconProfile';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import { getLocaleValue, removeAttributes } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import { getColorNameById } from 'helpers/colors';
import { prem } from 'styles/helpers/spacing';
import Button from 'components/common/button/Button';
import { GenericDiv, SvgWrapper } from 'styles/helpers/utils';
import QuestionnaireButton from 'components/profile/QuestionnaireButton';
import { ROUTES } from 'constants/routes';
import ButtonDoIdentification from 'components/relations/relation/interaction/ButtonDoIdentification';
import RelationInteractionIconProfiles from 'components/relations/relation/interaction/interaction-icon-profiles/RelationInteractionIconProfiles';
import { disableGetModuleButton, hideGetModuleButton } from 'helpers/modules';
import useAPITest from 'hooks/api/useAPITest';
import { ModuleTestContext, ModuleTestContextProps } from 'contexts/ModuleTestContext';
import { MODULES_TEST_IDS } from 'constants/global';
import useFetch from 'hooks/api/useFetch';
import useIonNavContext from 'hooks/useIonNavContext';

export interface RelationInteractionProps {
  interaction: IRelationInteraction;
  user?: IUser;
  hasModule?: boolean;
  onSubscription?: () => void;
  onIdentifyingRelation?: () => void;
  relationTestDatas?: ITest | undefined;
  canLaunchRelationManagementTest?: boolean;
  hasAlreadyUsedTestOnRelation?: boolean;
}

const RelationInteraction: React.FC<RelationInteractionProps> = ({
  interaction,
  hasModule,
  onSubscription,
  user,
  onIdentifyingRelation,
  relationTestDatas,
  canLaunchRelationManagementTest,
  hasAlreadyUsedTestOnRelation,
}) => {
  const { t, i18n } = useTranslation('translation');
  const { initModuleTest } = useAPITest();
  const { showLoading, hideLoading } = useFetch();
  const { loadTestInfos } = useContext(ModuleTestContext) as ModuleTestContextProps;
  const {
    icons: [iconRelationSubscription],
  } = useIcons([ICON_NAMES.RELATION_SUBSCRIPTION_MARKER]);
  const leftProfile = {
    primaryColorId: interaction.userPrimaryColorId,
    ...(interaction.userSecondaryColorId
      ? { secondaryColorId: interaction.userSecondaryColorId }
      : { secondaryColor: 'var(--ion-white-to-black)' }),
  } as IconProfileProps;
  const coloursNames = useMemo(() => {
    if (interaction.userPrimaryColorId && interaction.relationPrimaryColorId) {
      return `${getColorNameById(
        interaction.userPrimaryColorId,
        i18n.language,
      )} / ${getColorNameById(interaction.relationPrimaryColorId, i18n.language)}`;
    }
    return '';
  }, [interaction]);
  const gotoQuiz = () => {
    const link = t('common.quizlink', { email: user?.email }) || '';
    link && window.open(link, '_blank');
  };

  const onClickInitiateTest = async () => {
    showLoading();
    if (user) {
      const init = await initModuleTest(
        user.id,
        `${user.token?.type} ${user.token?.value}`,
        MODULES_TEST_IDS.RELATION_MANAGEMENT,
        interaction.relationId as number,
      );

      init && (await loadTestInfos(user));
    }
    hideLoading();
  };

  const { gotoUrl } = useIonNavContext();

  return (
    <RelationInteractionWrapper>
      {!!interaction.id && <ColoursNames>{`${coloursNames}`}</ColoursNames>}
      <RelationInteractionIconProfiles interaction={interaction} />
      {!leftProfile.primaryColorId ? (
        <>
          <RelationInteractionDesc>{t('relations.management.noprofile')}</RelationInteractionDesc>
          <GenericDiv width="75%" minWidth={prem(200)} center={true}>
            {!user?.profileInfo?.tools.profile && (
              <QuestionnaireButton
                label={t('profile.discover.title')}
                link={ROUTES.PROFILE.INDEX}
                internalLink={true}
              />
            )}
            {!!user?.profileInfo?.tools.profile && (
              <Button color="accent" fill="outline" expand={true} onClick={gotoQuiz}>
                {t('common.accessquiz')}
              </Button>
            )}
          </GenericDiv>
        </>
      ) : !hasModule ? (
        <>
          {/* Has test */}
          {relationTestDatas ? (
            <>
              <RelationInteractionDesc
                dangerouslySetInnerHTML={{
                  __html: removeAttributes(getLocaleValue(interaction.description, i18n.language)),
                }}
              />
              {!!interaction.id && (
                <RelationInteractionButtonSubscribeWrapper>
                  <RelationInteractionButtonSubscribe
                    className={interaction.subscribed ? 'subscribed' : ''}
                    onClick={onSubscription}
                  >
                    <span>
                      {t(
                        interaction.subscribed
                          ? 'common.subscriber'
                          : 'relations.management.subscribetorelation',
                      )}
                    </span>
                    <SvgWrapper>{iconRelationSubscription.icon}</SvgWrapper>
                  </RelationInteractionButtonSubscribe>
                </RelationInteractionButtonSubscribeWrapper>
              )}
            </>
          ) : (
            // Has no current test
            <div className="no-current-test">
              <RelationInteractionDesc>
                {t('relations.management.nomanagementmodule')}
              </RelationInteractionDesc>
              <div className="ctas">
                {user &&
                  !onIdentifyingRelation &&
                  canLaunchRelationManagementTest &&
                  !hideGetModuleButton(user as IUser) &&
                  !disableGetModuleButton(user as IUser) && (
                    <Button
                      onClick={onClickInitiateTest}
                      color="accent"
                      fill="outline"
                      expand={true}
                    >
                      {t('common.testmodule')}
                    </Button>
                  )}

                {user &&
                  !onIdentifyingRelation &&
                  !hideGetModuleButton(user as IUser) &&
                  !disableGetModuleButton(user as IUser) && (
                    <div className="buy-module">
                      {!canLaunchRelationManagementTest && (
                        <>
                          {hasAlreadyUsedTestOnRelation ? (
                            <div className="cannot-test-anymore">
                              {t('relations.management.getaftertestexpiration')}
                            </div>
                          ) : (
                            <div className="cannot-test-anymore">
                              {t('relations.management.getafterusingtest')}
                            </div>
                          )}
                        </>
                      )}
                      <Button onClick={() => {
                        gotoUrl(ROUTES.CONTACT);
                      }} color="accent" fill="outline" expand={true}>
                        {t('common.getmodule')}
                      </Button>
                    </div>
                  )}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <RelationInteractionDesc
            dangerouslySetInnerHTML={{
              __html: removeAttributes(getLocaleValue(interaction.description, i18n.language)),
            }}
          />
          {!!interaction.id && (
            <RelationInteractionButtonSubscribeWrapper>
              <RelationInteractionButtonSubscribe
                className={interaction.subscribed ? 'subscribed' : ''}
                onClick={onSubscription}
              >
                <span>
                  {t(
                    interaction.subscribed
                      ? 'common.subscriber'
                      : 'relations.management.subscribetorelation',
                  )}
                </span>
                <SvgWrapper>{iconRelationSubscription.icon}</SvgWrapper>
              </RelationInteractionButtonSubscribe>
            </RelationInteractionButtonSubscribeWrapper>
          )}
        </>
      )}
      {onIdentifyingRelation && user?.profileInfo?.colors?.primary && (
        <ButtonDoIdentification onClick={onIdentifyingRelation} />
      )}
    </RelationInteractionWrapper>
  );
};

export default RelationInteraction;
