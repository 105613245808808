import useAPIPayableTools from 'hooks/api/useAPIPayableTools';
import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { IComponentWithChildren, IPayableTools } from 'types/app';
import { UserContext, UserContextProps } from 'contexts/UserContext';

export interface PayableToolsContextProps {
  payableTools: IPayableTools;
  loadPayableTools: () => Promise<void>;
}

export interface PayableToolsProviderProps extends IComponentWithChildren {
  payableTools?: IPayableTools;
}

const PayableToolsContext = createContext<PayableToolsContextProps | null>(null);
const PayableToolsProvider: FC<PayableToolsProviderProps> = ({ children }) => {
  const { getPayableTools } = useAPIPayableTools();
  const [payableTools, setPayableTools] = useState<IPayableTools>([]);
  const { user } = useContext(UserContext) as UserContextProps;

  const loadPayableTools = async () => {
    setPayableTools((await getPayableTools()) as IPayableTools);
  };

  useEffect(() => {
    if (user) {
      loadPayableTools();
    }
  }, [user]);

  return (
    <PayableToolsContext.Provider
      value={{
        payableTools: payableTools,
        loadPayableTools: loadPayableTools,
      }}
    >
      {children}
    </PayableToolsContext.Provider>
  );
};

export { PayableToolsContext, PayableToolsProvider };
