import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from 'layout/page/Page.layout';
import HasProfile from 'pages/profile/has-profile/HasProfile';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import useAPIAuth, { USER_PROFILE_INFO_SOURCE } from 'hooks/api/useAPIAuth';
import DiscoverProfile, { DiscoverMode } from 'pages/profile/discover-profile/DiscoverProfile';
import { IUser } from 'types/app';
import AnswerQuestionnaire from 'pages/profile/answer-questionnaire/AnswerQuestionnaire';
import { useLocation } from 'react-router';
import useIcons, { ICON_NAMES, IIcon } from 'hooks/useIcons';
import GuestScreen from 'components/guest-screen/GuestScreen';
import { ModalContext, ModalContextProps } from 'contexts/ModalContext';
import AuthentificationModal from 'components/modal/authentification/AuthentificationModal';
// import { PayableToolsContext, PayableToolsContextProps } from 'contexts/PayableToolsContext';
// import { useIonViewWillEnter } from '@ionic/react';
import PrivilegedCompanyModal from 'components/profile/has-privileged-company-modal/PrivilegedCompanyModal';
import useIonNavContext from 'hooks/useIonNavContext';
import GetProfile from 'pages/profile/get-profile/GetProfile';

const Profile: React.FC = () => {
  const { t } = useTranslation('translation');
  const { gotoUrl } = useIonNavContext();
  const {
    icons: [myProfile, mySummary, myMotivation, colorProfile],
  } = useIcons([
    ICON_NAMES.MY_PROFILE,
    ICON_NAMES.MY_SUMMARY,
    ICON_NAMES.MY_MOTIVATION,
    ICON_NAMES.COLOUR_PROFILE,
  ]);
  const { getUseDetail } = useAPIAuth();
  const { user, isGuest } = useContext(UserContext) as UserContextProps;
  const [isLoading, setLoading] = useState(true);
  // const { payableTools, loadPayableTools } = useContext(
  //   PayableToolsContext,
  // ) as PayableToolsContextProps;
  const [title, setTitle] = useState(' ');
  const [isInPrivilegedCompany, setIsInPrivilegedCompany] = useState(
    user?.profileInfo?.isInPrivilegedCompany,
  );
  const location = useLocation();
  const pathName: string = location.pathname || location.location.pathname;
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;

  // Which component to show
  const hasProfile = !!user?.profileInfo?.colors?.primary;
  const isToDiscoverProfile = !user?.profileInfo?.tools.profile;
  const questionnaireBtnLabel = t('profile.questionnaire.questionnairebuttonlabel') || '';

  // Resolve title according do profile
  const resolveTitle = (u?: IUser) => {
    let tmpTitle = t('common.profile');

    // Discover profile
    u && !u.profileInfo?.tools.profile && (tmpTitle = t('profile.discover.title'));
    // Questionnaire
    u?.profileInfo?.isToQuestionnaire && (tmpTitle = t('profile.questionnaire.title'));
    setTitle(tmpTitle);
  };
  // Load user info
  const loadUserInfo = async () => {
    if (user) {
      setLoading(true);
      const result = await getUseDetail(user);

      resolveTitle(result);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadUserInfo();
  }, []);

  useEffect(() => {
    setIsInPrivilegedCompany(user?.profileInfo?.isInPrivilegedCompany);
    // setIsInPrivilegedCompany(true);
  }, [user]);

  useEffect(() => {
    if (user && isInPrivilegedCompany && !user?.profileInfo?.tools.profile) {
      // if (isInPrivilegedCompany) {
      updateModalConf({
        component: (
          <PrivilegedCompanyModal
            email={user?.email || ''}
            companyName={user?.profileInfo?.companyName || ''}
            onClose={() => {
              updateModalConf();
            }}
          />
        ),
      });
    }
  }, [isInPrivilegedCompany]);

  // useIonViewWillEnter(() => {
  //   if (user) {
  //     const shouldBuyProfile =
  //       user.profileInfo?.isToQuestionnaire &&
  //       user.profileInfo?.isMemberTypeIndividualGroup &&
  //       user?.profileInfo?.groupId !== 1 &&
  //       !user?.profileInfo.hasPayedProfile &&
  //       !user.profileInfo.profilePaymentDate;

  //     if (shouldBuyProfile) {
  //       loadPayableTools();
  //     }
  //   }
  // });

  if (pathName && !pathName.includes('profile')) {
    return <></>;
  }

  // Invité
  if (isGuest) {
    return (
      <PageLayout headerProps={{ title: title, hideNotification: true }}>
        <GuestScreen
          title={t('profile.guest.title') as string}
          description={t('profile.guest.description') as string}
          cta={{
            label: t('profile.guest.cta.label') as string,
            onClick: () => {
              updateModalConf({
                component: <AuthentificationModal />,
              });
            },
          }}
        />
      </PageLayout>
    );
  }

  // 1- tsy manana outil
  // 2- tsy namaly questionnaire
  // 3- visible ou pss
  if (!isLoading) {
    // No profile
    if (isToDiscoverProfile) {
      return (
        <PageLayout headerProps={{ title: title }}>
          <DiscoverProfile mode={DiscoverMode.WITH_FAQS} />
        </PageLayout>
      );
    }

    // Questionnaire
    if (user.profileInfo?.isToQuestionnaire) {
      if (
        user.profileInfo?.isMemberTypeSiteMemberGroup &&
        user?.profileInfo?.source === USER_PROFILE_INFO_SOURCE.ACADEMY
      ) {
        return (
          <PageLayout headerProps={{ title: title }}>
            <AnswerQuestionnaire
              message={t('profile.questionnaire.sitemembersmessage.message')}
              btnLabel={questionnaireBtnLabel}
              btnLink={t('profile.questionnaire.sitemembersmessage.link', { email: user.email })}
            />
          </PageLayout>
        );
      }
      if (
        user.profileInfo?.isMemberTypeSiteMemberGroup &&
        user?.profileInfo?.source === USER_PROFILE_INFO_SOURCE.QUESTIONNAIRE
      ) {
        return (
          <PageLayout headerProps={{ title: title }}>
            <AnswerQuestionnaire
              message={t('profile.questionnaire.boughtprofilefromquestionnairemessage.message')}
              btnLabel={t('profile.questionnaire.boughtprofilefromquestionnairemessage.cta')}
              btnLink={t('profile.questionnaire.boughtprofilefromquestionnairemessage.link', {
                email: user?.email,
              })}
            />
          </PageLayout>
        );
      }
      if (
        user.profileInfo?.isMemberTypeIndividualGroup &&
        user?.profileInfo?.groupId !== 1 &&
        !user?.profileInfo.hasPayedProfile &&
        !user.profileInfo.profilePaymentDate
      ) {
        return (
          <PageLayout headerProps={{ title: title }}>
            <GetProfile
              message={t('profile.questionnaire.particulargroupmessage.message')}
              btnLabel={t('profile.questionnaire.particulargroupmessage.cta') as string}
              onClick={() => {
                // achat du profile
                // buy profile
                gotoUrl(t('profile.questionnaire.particulargroupmessage.link', { email: user?.email }));
              }}
            />
          </PageLayout>
        );
      }

      return (
        <PageLayout headerProps={{ title: title }}>
          <AnswerQuestionnaire
            message={t('profile.questionnaire.defaulmessage.message')}
            btnLabel={questionnaireBtnLabel}
            btnLink={t('profile.questionnaire.defaulmessage.link', {
              email: user?.email,
            })}
          />
        </PageLayout>
      );
    }

    // Has tool
    if (!isToDiscoverProfile) {
      if (user.profileInfo?.isProfileAvailableSoon) {
        return (
          <PageLayout headerProps={{ title: title }}>
            <DiscoverProfile mode={DiscoverMode.AVAILABLE_SOON} />
          </PageLayout>
        );
      }
    }

    // Has color
    if (hasProfile) {
      const t: Array<IIcon> = [];
      !!user?.profileInfo?.colors?.primary && t.push(myProfile);
      !!user?.profileInfo?.pdfSummary && t.push(mySummary);
      t.push(myMotivation);
      !!user.profileInfo?.hasValidatedProfile && t.push(colorProfile);

      return (
        <PageLayout headerProps={{ title: title }}>
          <HasProfile tabs={t} />
        </PageLayout>
      );
    }
  }

  return <PageLayout headerProps={{ title: title }} />;
};

export default Profile;
