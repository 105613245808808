import { useContext } from 'react';
import useFetch from 'hooks/api/useFetch';
import { API, MODULES_CODES, NOTIFICATIONS, PAYABLE_TOOLS_CODES } from 'constants/global';
import { IModule, IModules, IProducts, IResourceTrainings, IUser } from 'types/app';
import { AxiosRequestConfig } from 'axios';
import { ToastContext, ToastContextProps } from 'contexts/ToastContext';
import { IHttpResponse } from 'helpers/api';
import { useTranslation } from 'react-i18next';
import useIcons, { ICON_NAMES, IIcon } from 'hooks/useIcons';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { ROUTES } from 'constants/routes';
import { detectLanguage, getBase64User } from 'helpers/utils';
import { ElearningContext, ElearningContextProps } from 'contexts/ElearningContext';
import {
  disableGetModuleButton,
  hideGetModuleButton,
  showBElearningButton,
} from 'helpers/modules';

export interface IUseAPIResources {
  getModules: () => Promise<IModules>;
  getProducts: (apiURL: string, field: string) => Promise<IProducts>;
  getTrainings: () => Promise<IResourceTrainings | undefined>;
}

export interface IModuleExtraInfos {
  code: string;
  paymentCode: string;
  icon: IIcon;
  userHasAccess: boolean;
  hide?: boolean;
  route?: string;
  onClick?: any;
  canGet?: boolean;
}

type ProductRawTranslation = { locale: string; description: string; libelle: string };

function useAPIResources(): IUseAPIResources {
  const {
    icons: [myMotivation, manageRelation, prepareAppointment, mapDraw, elearning, profile],
  } = useIcons([
    ICON_NAMES.MY_MOTIVATION,
    ICON_NAMES.RELATION_MNGT,
    ICON_NAMES.RELATION_APPOINTMENT,
    ICON_NAMES.MAP_DRAW,
    ICON_NAMES.ELEARNING,
    ICON_NAMES.PROFILE,
  ]);

  const { user } = useContext(UserContext) as UserContextProps;
  const { openElearning, closeElearning } = useContext(ElearningContext) as ElearningContextProps;
  const { i18n } = useTranslation('translation');
  const { setToast } = useContext(ToastContext) as ToastContextProps;
  const { getRequest, showLoading, hideLoading } = useFetch<any>();

  // Parse products: books, trainings
  const parseProducts = (productRaw: any): IProducts => {
    if (Array.isArray(productRaw)) {
      return productRaw.map((product) => {
        const translations: Array<ProductRawTranslation> = product.translations;
        const translationFr: ProductRawTranslation | undefined = translations.find(
          (t) => t.locale === 'fr',
        );
        const translationEn: ProductRawTranslation | undefined =
          translations.find((t) => t.locale === 'en') || translationFr;

        return {
          id: product.id,
          title: {
            fr: translationFr?.libelle || '',
            en: translationEn?.libelle || '',
          },
          description: {
            fr: translationFr?.description || '',
            en: translationEn?.description || '',
          },
          order: product.order,
          imageUrl: product.image_url,
          getUrl: product.purchase_url,
          // MBOLA MIANDRY CONFIG ANAH API
          getTitle: {
            fr: product?.purchase_title,
            en: product?.purchase_title,
          },
        };
      });
    }
    return [];
  };

  // Load products
  const getProducts = async (apiURL: string, field = 'livres') => {
    showLoading();

    let products: IProducts = [];

    try {
      const options: AxiosRequestConfig = {
        url: apiURL,
        method: 'get',
      };

      const result: IHttpResponse = await getRequest(options);
      const productState = result.data.data[field];

      if (Array.isArray(productState)) {
        products = parseProducts(productState);
      } else {
        if (result.data.message) {
          setToast({
            message: result.data.message,
            status: 'danger',
          });
        }
      }
    } catch (e: unknown) {
      console.log('Error while getting advices : ', e);
      setToast({
        message: (e as string) || '',
        status: 'danger',
      });
    } finally {
      hideLoading();
    }

    return products;
  };

  const parseTrainings = (datas: any): IResourceTrainings => {
    const introductionText = datas.texte_introduction;
    const trainings = datas.formations;
    return {
      introduction: {
        fr: introductionText.nom_fr,
        en: introductionText.nom_en,
      },
      trainings: parseProducts(trainings) || [],
    };
  };

  const getTrainings = async () => {
    showLoading();

    try {
      const options: AxiosRequestConfig = {
        url: API.ROUTES.RESOURCES.TRAININGS,
        method: 'get',
      };

      const result: IHttpResponse = await getRequest(options);
      const datasState = result.data.data;
      if (datasState) {
        const trainings = parseTrainings(datasState);
        return trainings;
      } else {
        if (result.data.message) {
          setToast({
            message: result.data.message,
            status: 'danger',
          });
        }
      }
    } catch (e: unknown) {
      console.log('Error while getting advices : ', e);
      setToast({
        message: (e as string) || '',
        status: 'danger',
      });
    } finally {
      hideLoading();
    }

    return undefined;
  };

  const openElearningModule = () => {
    if (user?.profileInfo?.tools.lmsStress || user?.profileInfo?.tools.lmsWorkTogether) {
      const base64 = getBase64User(user.email as string);
      openElearning(`${API.ELEARNING_PATH}${base64}&t=${new Date().getTime()}`);
    } else {
      closeElearning();
    }
  };

  const checkCanBuyModule = (moduleCode: string) => {
    if (moduleCode !== MODULES_CODES.ELEARNING) {
      if (hideGetModuleButton(user as IUser)) {
        return false;
      } else {
        if (disableGetModuleButton(user as IUser)) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return showBElearningButton(user as IUser);
    }
  };

  const modulesExtraInfos: Array<IModuleExtraInfos> = [
    {
      code: MODULES_CODES.PROFILE,
      paymentCode: PAYABLE_TOOLS_CODES.COLOR_PROFILE,
      icon: profile,
      userHasAccess: !!user?.profileInfo?.hasPayedProfile,
      route: ROUTES.PROFILE.MY_PROFILE,
      canGet: checkCanBuyModule(MODULES_CODES.PROFILE),
      hide: true,
    },
    {
      code: MODULES_CODES.MOTIVATION,
      paymentCode: PAYABLE_TOOLS_CODES.MOTIVATION,
      icon: myMotivation,
      userHasAccess: false,
      route: ROUTES.PROFILE.MY_MOTIVATION,
      canGet: checkCanBuyModule(MODULES_CODES.MOTIVATION),
    },
    {
      code: MODULES_CODES.RELATION,
      paymentCode: PAYABLE_TOOLS_CODES.MANAGEMENT,
      icon: manageRelation,
      userHasAccess: user?.profileInfo?.notifications?.includes(NOTIFICATIONS.MANAGEMENT) || false,
      route: ROUTES.RELATIONS.INDEX,
      canGet: checkCanBuyModule(MODULES_CODES.RELATION),
    },
    {
      code: MODULES_CODES.APPOINTMENT,
      paymentCode: PAYABLE_TOOLS_CODES.APPOINTMENT,
      icon: prepareAppointment,
      userHasAccess: user?.profileInfo?.notifications?.includes(NOTIFICATIONS.SALE) || false,
      route: ROUTES.RELATIONS.INDEX,
      canGet: checkCanBuyModule(MODULES_CODES.APPOINTMENT),
    },
    {
      code: MODULES_CODES.MAP,
      paymentCode: PAYABLE_TOOLS_CODES.GROUP_CARTOGRAPHY,
      icon: mapDraw,
      userHasAccess: false,
      canGet: checkCanBuyModule(MODULES_CODES.MAP),
      hide: true,
    },
    {
      code: MODULES_CODES.ELEARNING,
      paymentCode: PAYABLE_TOOLS_CODES.ELEARNING_WORKING_TOGETHER,
      icon: elearning,
      userHasAccess:
        !!user?.profileInfo?.tools.lmsWorkTogether || !!user?.profileInfo?.tools?.lmsStress,
      // userHasAccess: false,
      onClick: () =>
        (!!user?.profileInfo?.tools.lmsWorkTogether || !!user?.profileInfo?.tools?.lmsStress) &&
        openElearningModule(),
      canGet: checkCanBuyModule(MODULES_CODES.ELEARNING),
    },
    {
      code: MODULES_CODES.TALENTS,
      paymentCode: PAYABLE_TOOLS_CODES.TALENT_QUESTIONNAIRE,
      icon: mapDraw,
      userHasAccess: !!user?.profileInfo?.tools?.talent,
      canGet: checkCanBuyModule(MODULES_CODES.TALENTS),
      hide: true,
    },
  ];

  // useEffect(() => {}, [payableTools]);
  const getModuleExtraInfos = (code: string): IModuleExtraInfos => {
    return modulesExtraInfos.find((moduleExtraInfo) => {
      return moduleExtraInfo.code === code;
    }) as IModuleExtraInfos;
  };

  const formatModules = (objectToFormat: any): IModules => {
    return Object.values(objectToFormat)
      .map((item: any): IModule | undefined => {
        const moduleExtraInfos = getModuleExtraInfos(item.code);
        if (moduleExtraInfos) {
          return {
            title: item.libelle,
            code: item.code,
            paymentCode: moduleExtraInfos?.paymentCode,
            description: item.description,
            hasAccess: moduleExtraInfos?.userHasAccess,
            icon: moduleExtraInfos.icon,
            route: moduleExtraInfos.route,
            onClick: moduleExtraInfos.onClick,
            canGet: moduleExtraInfos.canGet,
            videoCta: item.libelle_button,
            videoUrl: item.url_video_excerpt,
          };
        } else return undefined;
      })
      .filter((item) => {
        if (item) {
          const moduleExtraInfos = getModuleExtraInfos(item.code);
          return !moduleExtraInfos.hide && !!item;
        }
      }) as IModules;
  };

  const getModules = async () => {
    showLoading();

    let modules: IModules = [];

    try {
      const options: AxiosRequestConfig = {
        url: `${API.ROUTES.RESOURCES.MODULES}/${detectLanguage(i18n.language)}`,
        method: 'get',
        headers: {
          Authorization: API.BASIC_TOKEN,
        },
      };
      const result: IHttpResponse = await getRequest(options);
      const modulesState = result.data.data?.modules;
      if (modulesState) {
        modules = formatModules(modulesState);
      } else {
        if (result.data.message) {
          setToast({
            message: result.data.message,
            status: 'danger',
          });
        }
      }
    } catch (e: unknown) {
      console.log('Error while getting modules : ', e);
    } finally {
      hideLoading();
    }

    return modules;
  };

  return {
    getModules,
    getProducts,
    getTrainings,
  };
}

export default useAPIResources;
