import React, { useCallback, useContext, useEffect, useState } from 'react';
import PageLayout from 'layout/page/Page.layout';
import { HomeSwiperContainer, HomeWrapper } from 'pages/home/Home.styled';
import VideoThumbnailsSlider from 'components/slider/video-thumbnails-slider/VideoThumbnailsSlider';
import { VideoPlayerContext, VideoPlayerContextProps } from 'contexts/VideoPlayerContext';
import { IAudios, IHome, IPodcastChannel, IVideos } from 'types/app';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import useAPIHome from 'hooks/api/useAPIHome';
import LinkedinPosts from 'pages/home/linkedin-posts/LinkedinPosts';
import BlogPosts from 'pages/home/blog-posts/BlogPosts';
import ContentLoader from 'react-content-loader';
import { Container } from 'layout/page/Page.layout.styled';
import { GenericDiv } from 'styles/helpers/utils';
import { prem } from 'styles/helpers/spacing';
import Button from 'components/common/button/Button';
import { ROUTES } from 'constants/routes';
import { useTranslation } from 'react-i18next';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import SpotifyAudios from 'pages/home/spotify-audios/SpotifyAudios';
import PictoBlog from 'assets/images/picto-blog.png';
import { LANGUAGES } from 'constants/global';
import { useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
// import useAPITest from 'hooks/api/useAPITest';

const Home: React.FC = () => {
  const { setVideoUrl } = useContext(VideoPlayerContext) as VideoPlayerContextProps;
  const { getHomeDatas, getSpotifyRssFluxDatas } = useAPIHome();
  const [isVisible, setIsVisible] = useState(true);
  const { t } = useTranslation('translation');
  const { user, isGuest } = useContext(UserContext) as UserContextProps;
  const { i18n } = useTranslation('i18n');

  const onPlayVideo = useCallback((videoUrl: string) => {
    setVideoUrl(videoUrl);
  }, []);

  const {
    icons: [iconYoutube, iconVimeo, iconTiktok, iconLinkedin, iconMicrophone],
  } = useIcons([ICON_NAMES.YOUTUBE, ICON_NAMES.VIMEO, ICON_NAMES.TIKTOK, ICON_NAMES.LINKEDIN, ICON_NAMES.MICROPHONE]);

  const [youtubeVideos, setYoutubeVideos] = useState<IVideos>();
  const [tiktokVideos, setTiktokVideos] = useState<IVideos>();
  const [vimeoVideos, setVimeoVideos] = useState<IVideos>();
  const [postArticles, setPostArticles] = useState<IHome.IArticlesPost[]>();
  const [postLinkedins, setPostLinkedins] = useState<IHome.ILinkedinPost[]>();
  const [spotifyRssDatas, setSpotifyRssDatas] = useState<IAudios>();
  const [podcastChannels, setPodcastChannels] = useState<{
    [x: string]: IPodcastChannel;
  }>();

  const loadHomeDatas = useCallback(async () => {
    const homeDatas = await getHomeDatas();
    setYoutubeVideos(homeDatas?.youtubeVideos as IVideos);
    setTiktokVideos(homeDatas?.tiktokVideos as IVideos);
    setVimeoVideos(homeDatas?.vimeoVideos as IVideos);
    setPostArticles(homeDatas?.articlePosts as IHome.IArticlesPost[]);
    setPostLinkedins(homeDatas?.linkedinPosts as IHome.ILinkedinPost[]);
    const spotifyDatas = await getSpotifyRssFluxDatas();
    setPodcastChannels(homeDatas?.podcastsChannels);
    i18n.language === LANGUAGES.fr ? setSpotifyRssDatas(spotifyDatas) : setSpotifyRssDatas([]);
  }, []);

  // const { getTestInfos } = useAPITest();

  // useEffect(() => {
  //   loadHomeDatas();
  //   // user && getTestInfos(user?.id, `${user?.token?.type} ${user?.token?.value}`);
  // }, []);

  useIonViewDidEnter(() => {
    console.log('useIonViewDidEnter');
    setIsVisible(true);
  });

  useIonViewWillLeave(() => {
    console.log('useIonViewWillLeave');
    setIsVisible(false);
  });

  useEffect(() => {
    console.log('home page visible state', isVisible);
    isVisible && loadHomeDatas();
  }, [isVisible]);

  const openWindowBlank = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <PageLayout
      headerProps={{
        showLogo: true,
      }}
    >
      <HomeWrapper className="home-wrapper app-page">
        <div className="home-main">
          <Container className="texts-container">
            <p className="desc">{t('homepage.introtext')}</p>
            {!isGuest && (
              <Container className="button-container">
                <GenericDiv width="25%" minWidth={prem(240)}>
                  <Button
                    color="accent"
                    expand={true}
                    fill="outline"
                    routerLink={ROUTES.PROFILE.INDEX}
                  >
                    {t('homepage.discovermyprofile')}
                  </Button>
                </GenericDiv>
                {user?.profileInfo?.tools.digital && (
                  <GenericDiv width="25%" minWidth={prem(240)}>
                    <Button
                      color="accent"
                      expand={true}
                      fill="outline"
                      routerLink={ROUTES.TRAINING}
                    >
                      {t('homepage.trainingtools')}
                    </Button>
                  </GenericDiv>
                )}
              </Container>
            )}
            <p
              className="news-feed-desc"
              dangerouslySetInnerHTML={{
                __html: t('homepage.newsfeed'),
              }}
            />
          </Container>
        </div>
        {youtubeVideos && youtubeVideos.length > 0 && (
          <HomeSwiperContainer className="youtube-videos-wrapper">
            <div
              className="section-channel"
              onClick={() => {
                openWindowBlank('https://www.youtube.com/c/SUPERGREENCOMCOLORS');
              }}
            >
              <span className="icon">{iconYoutube.icon}</span>
              <span className="label">{t('homepage.youtubevideos')}</span>
            </div>
            <div className="youtube-videos">
              <VideoThumbnailsSlider
                thumbnails={youtubeVideos as IVideos}
                onSelectItem={onPlayVideo}
                cssProps={'& .video-thumbnail-wrapper { min-height: 146px; }'}
                showBullets
              />
            </div>
          </HomeSwiperContainer>
        )}
        {vimeoVideos && vimeoVideos.length > 0 && (
          <HomeSwiperContainer className="youtube-videos-wrapper">
            <div
              className="section-channel"
              onClick={() => {
                // openWindowBlank('https://www.youtube.com/c/SUPERGREENCOMCOLORS');
              }}
            >
              <span className="icon">{iconVimeo.icon}</span>
              <span className="label">{t('homepage.vimeovideos')}</span>
            </div>
            <div className="vimeo-videos">
              <VideoThumbnailsSlider
                thumbnails={vimeoVideos as IVideos}
                onSelectItem={onPlayVideo}
                showBullets
              />
            </div>
          </HomeSwiperContainer>
        )}
        {spotifyRssDatas && spotifyRssDatas.length > 0 && (
          <>
            <div className="channels audio-channels">
              <div
                className="section-channel"
                onClick={() => {
                  openWindowBlank('https://www.linkedin.com/in/franck-jullien');
                }}
              >
                <span className="icon">{iconMicrophone.icon}</span>
                <span className="label">Podcasts</span>
              </div>
              <div className="other-channels">
                {podcastChannels &&
                  Object.values(podcastChannels).map((channel, key) => {
                    return (
                      <span key={key} onClick={() => openWindowBlank(channel.url)}>
                        {channel.icon}
                      </span>
                    );
                  })}
              </div>
            </div>
            <SpotifyAudios audios={spotifyRssDatas} />
            {/* <VideoThumbnailsSlider
                thumbnails={spotifyRssDatas as IVideos}
                onSelectItem={(videoUrl) => openWindowBlank(videoUrl)}
                showBullets
              /> */}
          </>
        )}
        {tiktokVideos && tiktokVideos.length > 0 && (
          <HomeSwiperContainer className="tiktok-videos-wrapper">
            <div
              className="section-channel"
              onClick={() => {
                openWindowBlank('https://www.tiktok.com/@francksupergreen');
              }}
            >
              <span className="icon">{iconTiktok.icon}</span>
              <span className="label">{t('homepage.shortvideos')}</span>
            </div>
            <div className="tiktok-videos">
              <VideoThumbnailsSlider
                thumbnails={tiktokVideos as IVideos}
                onSelectItem={onPlayVideo}
                thumbnailLoader={
                  <ContentLoader
                    viewBox="0 0 260 400"
                    backgroundColor={'#d3d3d3'}
                    foregroundColor="#c3c3c3"
                    animate={true}
                  >
                    <rect x="0" y="0" rx="5" ry="5" width="260" height="400" />
                    {/* <rect x="0" y="410" rx="4" ry="4" width="260" height="15" />
                    <rect x="0" y="430" rx="4" ry="4" width="45%" height="15" /> */}
                  </ContentLoader>
                }
              />
            </div>
          </HomeSwiperContainer>
        )}
        {postLinkedins && postLinkedins.length > 0 && (
          <div className="linkedin-posts-wrapper">
            <div
              className="section-channel"
              onClick={() => {
                openWindowBlank('https://www.linkedin.com/in/franck-jullien');
              }}
            >
              <span className="icon">{iconLinkedin.icon}</span>
              <span className="label">{t('homepage.linkedinpublications')}</span>
            </div>
            <LinkedinPosts posts={postLinkedins} />
          </div>
        )}
        {postArticles && postArticles.length > 0 && (
          <>
            <div
              className="section-channel"
              onClick={() => openWindowBlank('https://www.comcolors.com/fr/blog/')}
            >
              <span className="icon">
                <img src={PictoBlog} alt="picto-blog" />
              </span>
              <span className="label">Blog ComColors</span>
            </div>
            <BlogPosts posts={postArticles} />
          </>
        )}
      </HomeWrapper>
    </PageLayout>
  );
};

export default Home;
