import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import HeaderPage, { HeaderPageProps } from 'components/header/Header.page';
import { IComponentWithChildren } from 'types/app';

export interface PageLayoutProps extends IComponentWithChildren {
  headerProps?: HeaderPageProps;
  className?: string;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  className,
  children,
  headerProps = {},
}): React.ReactElement => {
  return (
    <IonPage>
      <HeaderPage {...headerProps} />
      <IonContent className={className} fullscreen>
        {children}
      </IonContent>
    </IonPage>
  );
};

export default PageLayout;
