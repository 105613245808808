import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from 'layout/page/Page.layout';
import { Container, PageInnerTitle } from 'layout/page/Page.layout.styled';
import { ROUTES } from 'constants/routes';
import { HEADER_HPADDING } from 'styles/helpers/spacing';
import { RelationsContext, RelationsContextProps } from 'contexts/RelationsContext';
import { matchPath } from 'react-router-dom';
import {
  IAdvice,
  IModule,
  IRelation,
  IRelationInteraction,
  IRelationInteractionSubscription,
  ITest,
} from 'types/app';
import {
  RelationsManagementWrapper,
  RelationSubscriptionButton,
} from 'pages/relations/manage/RelationManagement.styled';
import { useLocation } from 'react-router';
import { backPropsDefault } from 'pages/relations/Relations';
import Collapse from 'components/common/showhide/Collapse';
import ButtonBack from 'components/common/button/ButtonBack';
import { isMale } from 'helpers/relations';
import IconProfile from 'components/common/icons/icon-profile/IconProfile';
import { getColorNameById } from 'helpers/colors';
import useCollapse from 'hooks/useCollapse';
import useAPIRelations from 'hooks/api/useAPIRelations';
import RelationInteraction from 'components/relations/relation/interaction/RelationInteraction';
import { UserContext, UserContextProps } from 'contexts/UserContext';
import { SvgWrapper } from 'styles/helpers/utils';
import { useIonRouter } from '@ionic/react';
import { getLocaleValue, removeAttributes } from 'helpers/utils';
import { MODULES_CODES, MODULE_TEST_NAMES, NOTIFICATIONS } from 'constants/global';
import { ModalContext, ModalContextProps } from 'contexts/ModalContext';
import SubscriptionModal, {
  SubscriptionModalProps,
} from 'components/modal/subscription/SubscriptionModal';
import useIcons, { ICON_NAMES } from 'hooks/useIcons';
import Advice from 'components/advice/Advice';
import useAPIAdvices, { AdviceType } from 'hooks/api/useAPIAdvices';
import useAPINotificationSubscription, {
  SubscriptionType,
} from 'hooks/api/useAPINotificationSubscription';
import ButtonDoIdentification from 'components/relations/relation/interaction/ButtonDoIdentification';
import { ModuleTestContext, ModuleTestContextProps } from 'contexts/ModuleTestContext';
import ModuleTestAvailability from 'components/module-test/test-availability/ModuleTestAvailability';
import { ModuleContext, ModuleContextProps } from 'contexts/ModuleContext';
import TutoButtonPlayer from 'components/tuto-button-player/TutoButtonPlayer';

const RelationManagement: React.FC = () => {
  const { t, i18n } = useTranslation('translation');
  const { setRelationQuestionnaireFactory } = useContext(RelationsContext) as RelationsContextProps;
  const { user } = useContext(UserContext) as UserContextProps;
  const { updateModalConf } = useContext(ModalContext) as ModalContextProps;
  const { getRelationDetail } = useAPIRelations();
  const { loadSubscriptionDetail } = useAPINotificationSubscription();
  const { updateAdvice, setAdvicesRead } = useAPIAdvices();
  const { relationManagementTest, hasRelationManagementTest } = useContext(
    ModuleTestContext,
  ) as ModuleTestContextProps;
  const [isTestRelationManagementValid, setIsTestRelationManagementValid] = useState(false);
  const ionRouter = useIonRouter();
  const {
    icons: [iconRelationSubscription],
  } = useIcons([ICON_NAMES.RELATION_SUBSCRIPTION_MARKER]);

  // Start trick
  /*
   * useParams failed sometimes
   * */
  const location = useLocation();
  const match = matchPath(location.pathname || location.location.pathname, {
    path: `${ROUTES.RELATIONS.MANAGE}/:idRelation`,
    exact: true,
  });
  const idRelation = (match?.params as any)?.idRelation;
  // End trick
  const [currentRelation, setCurrentRelation] = useState<IRelation | undefined>();
  const [isLoading, setLoading] = useState<boolean>(true);

  const { getOpenState, updateStates } = useCollapse([
    { id: 'relationProfile' },
    { id: 'interaction', opened: true },
    { id: 'advices' },
  ]);
  const yourProfileLabel = useMemo((): string => {
    if (currentRelation) {
      const profileStr = `${t('common.profile')}`;
      const pronoun = isMale(currentRelation.pronoun) ? `${t('common.his')}` : `${t('common.her')}`;

      return `${pronoun.toLowerCase()} ${profileStr.toLowerCase()}`;
    }
    return '';
  }, [currentRelation]);
  const colorsNames = useMemo(() => {
    if (currentRelation && currentRelation.primaryColorId) {
      const primary = getColorNameById(currentRelation.primaryColorId, i18n.language);
      const secondary = currentRelation?.secondaryColorId
        ? `${getColorNameById(currentRelation.secondaryColorId, i18n.language)}`
        : '';

      return `${primary} / ${secondary}`;
    }

    return '';
  }, [currentRelation]);

  // Show modal subscription
  const showModalSubscription = (
    interaction: IRelationInteraction,
    subscription?: IRelationInteractionSubscription,
  ) => {
    const props: SubscriptionModalProps = {
      type: SubscriptionType.RELATION,
      relation: { colorId: interaction.relationPrimaryColorId, relationId: currentRelation?.id },
      texts: {
        title: t('common.subscribeto', {
          name: `${currentRelation?.fullName}`,
        }),
        subscriptionNote: t('relations.management.youreceivenotificaionnote'),
        unsubscriptionNote: t('relations.management.youdonotreceivenotificaionnote'),
      },
      subscriptionId: subscription?.id,
      deviceAuth: subscription?.deviceAuth,
      deviceEndPoint: subscription?.deviceEndPoint,
      deviceP256Dh: subscription?.deviceP256Dh,
      onValidate: () => {
        currentRelation && loadRelationData(currentRelation.id);
        updateModalConf();
      },
      onCancel: () => {
        updateModalConf();
      },
      ...(subscription && {
        hour: subscription.hour,
        day: subscription.day,
      }),
    };

    updateModalConf({
      component: <SubscriptionModal key={Math.random()} {...props} />,
    });
  };

  // Load subscription detail
  const loadInteractionDetail = async (interaction: IRelationInteraction) => {
    const subscriptionDetail = await loadSubscriptionDetail(interaction.subscriptionId || 0);

    if (subscriptionDetail) {
      showModalSubscription(interaction, subscriptionDetail);
    }
  };

  // Load relation data
  const loadRelationData = async (relationId: number) => {
    try {
      const rel = await getRelationDetail(relationId);
      if (rel?.advices?.length && user) {
        await setAdvicesRead(AdviceType.RELATION, user.id, relationId);
      }

      rel && setCurrentRelation(rel);
    } catch (e) {
      console.log('error: getting relation detail');
    } finally {
      setLoading(false);
    }
  };

  // Relation identification
  const onIdentifyingRelation = () => {
    setRelationQuestionnaireFactory();
    ionRouter.push(`${ROUTES.RELATIONS.IDENTIFY}/${currentRelation?.id}`);
  };

  // Prepare to open modal for relation subscription
  const onSubscriptionToInteraction = (interaction: IRelationInteraction) => {
    if (interaction.subscriptionId) {
      loadInteractionDetail(interaction);
    } else {
      showModalSubscription(interaction, undefined);
    }
  };

  // If the start under title is clicked
  const onSubscriptionMarkerClicked = () => {
    // Search the interaction which has subscription
    if (currentRelation?.interactions?.interactions) {
      const interaction = currentRelation?.interactions?.interactions?.find(
        (inter: IRelationInteraction) => {
          return inter.subscribed;
        },
      );

      !!interaction && onSubscriptionToInteraction(interaction);
    }
  };

  // When an advice is liked/unliked
  const onAdviceLiked = async (advice: IAdvice, state: boolean) => {
    const result = await updateAdvice(advice, state ? 1 : 0);

    if (result && currentRelation) {
      const relation: IRelation = { ...currentRelation };

      relation.advices = relation.advices?.map((a) => {
        if (a.id === result.id) {
          return result;
        }

        return a;
      });

      setCurrentRelation(relation);
    }
  };

  // Load relation detail
  useEffect(() => {
    if (idRelation) {
      const relId = parseInt(idRelation, 10);

      if (!isNaN(relId)) {
        loadRelationData(relId);
      } else {
        setLoading(false);
      }
    }
  }, [idRelation]);

  const checkValidRelationManagementTest = (test: ITest, relationToCheck: IRelation) => {
    return (
      !!user?.profileInfo?.colors?.primary &&
      !currentRelationManagementModule?.hasAccess &&
      !test.expiredAt &&
      getLocaleValue(test.themeName, i18n.language) ===
        getLocaleValue(MODULE_TEST_NAMES.RELATION_MANAGEMENT, i18n.language) &&
      test.daysLeft > 0 &&
      test.relationId === relationToCheck.id
    );
  };

  useEffect(() => {
    if (relationManagementTest && currentRelation) {
      setIsTestRelationManagementValid(
        checkValidRelationManagementTest(relationManagementTest, currentRelation as IRelation),
      );
    }
  }, [currentRelation, relationManagementTest]);

  const { modules } = useContext(ModuleContext) as ModuleContextProps;

  const [currentRelationManagementModule, setCurrentRelationManagementModule] = useState<IModule>();

  useEffect(() => {
    setCurrentRelationManagementModule(
      modules.find((item) => item.code === MODULES_CODES.RELATION) as IModule,
    );
  }, [modules]);

  return (
    <PageLayout headerProps={{ title: t('common.relations') }}>
      <RelationsManagementWrapper className="app-page">
        <Container padding={HEADER_HPADDING}>
          <ButtonBack {...backPropsDefault} />
        </Container>
        {currentRelation && (
          <>
            <Container>
              <TutoButtonPlayer tutoKey='add_relation' />
              <PageInnerTitle
                dangerouslySetInnerHTML={{
                  __html: t('relations.management.title', {
                    relationFullName: `${removeAttributes(currentRelation.fullName)}`,
                  }),
                }}
              />
              {currentRelation.subscribed && (
                <div className="subscription-marker-wrapper">
                  <RelationSubscriptionButton
                    onClick={() => {
                      onSubscriptionMarkerClicked();
                    }}
                  >
                    <SvgWrapper>{iconRelationSubscription.icon}</SvgWrapper>
                    <span>{t('common.subscriber')}</span>
                  </RelationSubscriptionButton>
                </div>
              )}
            </Container>
            <Container padding={0} className="page-inner-container">
              <Collapse
                label={yourProfileLabel}
                opened={getOpenState('relationProfile')}
                onOpened={(o) => updateStates('relationProfile', o)}
              >
                <Container className="relation-profile-container">
                  <div className="icon-profile-container">
                    <IconProfile
                      primaryColorId={currentRelation.primaryColorId}
                      secondaryColorId={currentRelation.secondaryColorId}
                      unspecifiedColor={currentRelation.unspecifiedColor}
                    />
                  </div>
                  {currentRelation.primaryColorId ? (
                    <div className="colours-content">
                      <p className="colours-names">{`${colorsNames}`}</p>
                      {currentRelation.personalityText && (
                        <p className="colours-comment">
                          {removeAttributes(
                            getLocaleValue(currentRelation.personalityText, i18n.language),
                          )}
                        </p>
                      )}
                    </div>
                  ) : (
                    <div className="colours-content">
                      <div
                        className="colours-comment"
                        dangerouslySetInnerHTML={{
                          __html: t('relations.management.doidentification', {
                            relationFullName: `${removeAttributes(currentRelation.fullName)}`,
                          }),
                        }}
                      />
                      <ButtonDoIdentification onClick={onIdentifyingRelation} />
                    </div>
                  )}
                </Container>
              </Collapse>
              <Collapse
                label={`${t('relations.management.yourrelation')}`}
                opened={getOpenState('interaction')}
                onOpened={(o) => updateStates('interaction', o)}
              >
                {relationManagementTest &&
                  hasRelationManagementTest &&
                  isTestRelationManagementValid && (
                    <ModuleTestAvailability
                      module={currentRelationManagementModule as IModule}
                      moduleTestInfos={relationManagementTest}
                    />
                  )}
                <Container className="relation-profile-container">
                  {user && (
                    <>
                      {!!currentRelation.interactions?.interactions?.length &&
                        !user.profileInfo?.hasValidatedProfile && (
                          <p
                            className="no-valid-profile-note"
                            dangerouslySetInnerHTML={{
                              __html: t('relations.management.novalidprofilenote'),
                            }}
                          />
                        )}
                      <RelationInteraction
                        interaction={{
                          id: 0,
                          userPrimaryColorId: user.profileInfo?.colors?.primary?.id || 0,
                          userSecondaryColorId: user.profileInfo?.colors?.secondary?.id || 0,
                          relationPrimaryColorId: currentRelation.primaryColorId || 0,
                          relationSecondaryColorId: currentRelation.secondaryColorId || 0,
                          relationId: currentRelation.id,
                          description: currentRelation.interactions?.generic,
                        }}
                        hasModule={user.profileInfo?.notifications?.includes(
                          NOTIFICATIONS.MANAGEMENT,
                        )}
                        canLaunchRelationManagementTest={
                          !relationManagementTest && !hasRelationManagementTest
                        }
                        user={user}
                        onIdentifyingRelation={
                          !currentRelation.primaryColorId ? onIdentifyingRelation : undefined
                        }
                        relationTestDatas={
                          isTestRelationManagementValid ? relationManagementTest : undefined
                        }
                        hasAlreadyUsedTestOnRelation={
                          currentRelation &&
                          relationManagementTest &&
                          relationManagementTest.relationId == currentRelation.id
                        }
                      />
                      {!!currentRelation.interactions?.interactions?.length &&
                        user.profileInfo?.hasVisibleProfile &&
                        (user.profileInfo?.notifications?.includes(NOTIFICATIONS.MANAGEMENT) ||
                          isTestRelationManagementValid) &&
                        currentRelation.interactions.interactions.map(
                          (inter: IRelationInteraction, index) => {
                            return (
                              <RelationInteraction
                                key={`inter-${index}`}
                                interaction={inter}
                                hasModule={true}
                                onSubscription={() => {
                                  onSubscriptionToInteraction(inter);
                                }}
                              />
                            );
                          },
                        )}
                    </>
                  )}
                </Container>
              </Collapse>
              <Collapse
                label={`${t('relations.management.advicesforrelation')}`}
                opened={getOpenState('advices')}
                onOpened={(o) => updateStates('advices', o)}
                className="collapse-advices"
              >
                <Container>
                  {!!currentRelation.advices?.length &&
                    currentRelation.advices.map((advice, index) => {
                      return (
                        <Advice
                          key={`advice-${index}`}
                          advice={advice}
                          onLike={(state: boolean) => {
                            onAdviceLiked(advice, state);
                          }}
                        />
                      );
                    })}
                  {!currentRelation.advices?.length && !currentRelation.subscribed && (
                    <p className="no-advices">{t('relations.management.noadvices')}</p>
                  )}
                </Container>
              </Collapse>
            </Container>
          </>
        )}
        {!isLoading && !currentRelation && (
          <Container>
            <PageInnerTitle
              dangerouslySetInnerHTML={{
                __html: t('message.error.relationnotfound'),
              }}
            />
          </Container>
        )}
      </RelationsManagementWrapper>
    </PageLayout>
  );
};

export default RelationManagement;
