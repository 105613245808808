import React, { useEffect, useState } from 'react';
import Button, { ButtonPropsBase } from 'components/common/button/Button';
import { GetModuleButtonWrapper } from 'components/resources/module/single-module/get-module/buy-module-button/GetModuleButton.styled';
import { useTranslation } from 'react-i18next';
import { IModule } from 'types/app';
import { MODULES_CODES } from 'constants/global';

export interface GetModuleButtonProps extends ButtonPropsBase {
  module: IModule;
  hasAccessToModule?: boolean;
  disable?: boolean;
  onClick?: () => void;
}
const GetModuleButton: React.FC<GetModuleButtonProps> = ({
  module,
  hasAccessToModule,
  disable,
  onClick,
  fill,
  color,
}) => {
  const { t } = useTranslation('translation');

  const [disabledButton, setDisabledButton] = useState(disable);

  useEffect(() => {
    setDisabledButton(disable || hasAccessToModule);
  }, [module, disable, hasAccessToModule]);

  return (
    <GetModuleButtonWrapper>
      {module.code !== MODULES_CODES.ELEARNING && (
        <Button
          color={color || 'accent'}
          fill={fill || 'solid'}
          type="submit"
          expand={true}
          disabled={disabledButton}
          onClick={() => {
            if (!disabledButton) {
              onClick && onClick();
            }
          }}
        >
          {`${t('resources.modules.getTheModule')}`}
        </Button>
      )}
      {module.code === MODULES_CODES.ELEARNING && (
        <Button
          color="accent"
          fill="solid"
          type="submit"
          expand={true}
          disabled={disabledButton}
          onClick={() => {
            if (!disabledButton) {
              onClick && onClick();
            }
          }}
        >
          {`${t('resources.modules.getTheModule')}`}
        </Button>
      )}
    </GetModuleButtonWrapper>
  );
};

export default GetModuleButton;
